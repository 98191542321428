import * as API from "@/services/API";

export default {
  getAccessParameters(meetingId) {
    return API.apiClient.get(`/meet/${meetingId}`);
  },
  getInterpreterAccessParameters(meetingId) {
    return API.apiClient.get(`/meet/${meetingId}/interpreter`);
  },
  getInterpretationChannelAccessParameters(meetingId, languageId) {
    return API.apiClient.get(`/meet/${meetingId}/channel/${languageId}`);
  },
  getAvailableLanguagesInMeeting(meetingId) {
    return API.apiClient.get(`/meet/${meetingId}/channel`);
  },
  createAblyTokenRequest(meetingId) {
    return API.apiClient.get(`/meet/${meetingId}/ably`);
  },
  getModerationSettings(meetingId) {
    return API.apiClient.get(`/meet/${meetingId}/moderation`);
  },
  setModerationSettings(meetingId, setting) {
    return API.apiClient.patch(`/meet/${meetingId}/moderation`, setting);
  }
};