import Vue from "vue";
import Vuex from "vuex";

import * as auth from "@/store/modules/Auth";
import * as user from "@/store/modules/User";
import * as joinMeeting from "@/store/modules/JoinMeeting";
import * as meetingSettings from "@/store/modules/MeetingSettings";


Vue.use(Vuex);

export default new Vuex.Store({
  strict: true,

  modules: {
    auth,
    user,
    joinMeeting,
    meetingSettings
  },
});
