import OT from "@opentok/client";
import * as helpers from "@/utils/helpers";
import JoinMeetingService from "@/services/JoinMeetingService";

export const namespaced = true;

export const audioSource = {
  FLOOR: 1,
  INTERPRETING: 2,
};

export const state = {
  loading: false,
  message: false,
  error: false,
  deviceAudioInputList: null,
  deviceAudioOutputList: null,
  deviceVideoInputList: null,
  deviceAudioInput: "default",
  deviceAudioOutput: "default",
  deviceVideoInput: null,
  layoutDisplayMyself: true,
  layoutShowNonVideoParticipants: true,
  layoutShowNamesOnVideo: true,
  audioAudioPanner: false,
  moderationParticipantsCanUnmute: true,
  moderationParticipantsCanShowWebcam: true,
  moderationParticipantsCanScreenshare: true,
  moderationParticipantsCanWriteInChat: true,
};


export const mutations = {
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_MESSAGE(state, message) {
    state.message = message;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  SET_AUDIO_INPUT_DEVICES(state, devices) {
    state.deviceAudioInputList = devices;
  },
  SET_AUDIO_OUTPUT_DEVICES(state, devices) {
    state.deviceAudioOutputList = devices;
  },
  SET_VIDEO_INPUT_DEVICES(state, devices) {
    state.deviceVideoInputList = devices;
  },
  SET_AUDIO_INPUT_DEVICE(state, deviceId) {
    state.deviceAudioInput = deviceId;
  },
  SET_AUDIO_OUTPUT_DEVICE(state, deviceId) {
    state.deviceAudioOutput = deviceId;
  },
  SET_VIDEO_INPUT_DEVICE(state, deviceId) {
    state.deviceVideoInput = deviceId;
  },
  SET_LAYOUT_DISPLAY_MYSELF(state, val) {
    state.layoutDisplayMyself = val;
  },
  SET_LAYOUT_SHOW_NON_VIDEO_PARTICIPANTS(state, val) {
    state.layoutShowNonVideoParticipants = val;
  },
  SET_LAYOUT_SHOW_NAMES_ON_VIDEO(state, val) {
    state.layoutShowNamesOnVideo = val;
  },
  SET_AUDIO_AUDIO_PANNER(state, val) {
    state.audioAudioPanner = val;
  },
  SET_MODERATION_PARTICIPANTS_CAN_UNMUTE(state, val) {
    state.moderationParticipantsCanUnmute = val;
  },
  SET_MODERATION_PARTICIPANTS_CAN_SHOW_WEBCAM(state, val) {
    state.moderationParticipantsCanShowWebcam = val;
  },
  SET_MODERATION_PARTICIPANTS_CAN_SCREENSHARE(state, val) {
    state.moderationParticipantsCanScreenshare = val;
  },
  SET_MODERATION_PARTICIPANTS_CAN_WRITE_IN_CHAT(state, val) {
    state.moderationParticipantsCanWriteInChat = val;
  },
};

export const actions = {
  getDevices({ commit }) {
    OT.getDevices((err, devices) => {
      commit("SET_AUDIO_INPUT_DEVICES", devices.filter((device) => device.kind === 'audioInput'));
      commit("SET_VIDEO_INPUT_DEVICES", devices.filter((device) => device.kind === 'videoInput'));
      //state.deviceVideoInput == null ? commit("SET_VIDEO_INPUT_DEVICE", devices.filter((device) => device.kind === 'videoInput')[0].deviceId) : null;
    });
    OT.getAudioOutputDevices().then((devices) => {
      commit("SET_AUDIO_OUTPUT_DEVICES", devices);
    });
  },
  fetchModerationSettings({ commit, rootState }) {
    commit("SET_LOADING", true);

    JoinMeetingService.getModerationSettings(rootState.joinMeeting.meeting_id)
      .then((response) => {
        commit("SET_MODERATION_PARTICIPANTS_CAN_UNMUTE", response.data.settings.moderation_allow_participants_to_unmute);
        commit("SET_MODERATION_PARTICIPANTS_CAN_SHOW_WEBCAM", response.data.settings.moderation_allow_participants_to_show);
        commit("SET_MODERATION_PARTICIPANTS_CAN_SCREENSHARE", response.data.settings.moderation_allow_participants_to_screenshare);
        commit("SET_MODERATION_PARTICIPANTS_CAN_WRITE_IN_CHAT", response.data.settings.moderation_allow_participants_to_write_in_chat);
        commit("SET_LOADING", false);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", helpers.getError(error));
      });
  },

  SET_MODERATION_SETTING_VALUE({ commit, rootState }, setting) {
    console.log(setting)
    JoinMeetingService.setModerationSettings(rootState.joinMeeting.meeting_id, setting).then(() => {
      commit("SET_LOADING", false);
    })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", helpers.getError(error));
      });
  }


};

export const getters = {

};

