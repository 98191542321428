import * as helpers from "@/utils/helpers";
import JoinMeetingService from "@/services/JoinMeetingService";
import Vue from 'vue';


export const namespaced = true;

export const audioSource = {
  FLOOR: 1,
  INTERPRETING: 2,
};

export const state = {
  meeting_id: null,
  user_role: null,
  meetingParticipants: [],
  availableLanguagesInMeeting: [],
  interpretationChannelLoading: false,
  interpretationChannelActive: false,
  audioSource: audioSource.FLOOR,
  interpreterConsoleChannels: [],
  selectedLangId: null,
  meta: null,
  links: null,
  loading: false,
  error: null,
};


export const mutations = {
  SET_MEETING_ID(state, meeting_id) {
    state.meeting_id = meeting_id;
  },
  SET_USER_ROLE(state, user_role) {
    state.user_role = user_role;
  },
  ADD_MEETING_PARTICIPANT(state, participant) {
    state.meetingParticipants.push(participant);
  },
  REMOVE_MEETING_PARTICIPANT(state, participant) {
    const idx = state.meetingParticipants.indexOf(participant);
    if (idx > -1) {
      state.meetingParticipants.splice(idx, 1);
    }
  },
  SET_AVAILABLE_LANGUAGES(state, languages) {
    state.availableLanguagesInMeeting = languages;
  },
  SET_SELECTED_LANGUAGE_ID(state, language_id) {
    state.selectedLangId = language_id;
  },
  SET_AUDIO_SOURCE(state, audio_source) {
    //audio source should be one of the audioSource const
    state.audioSource = audio_source;
  },
  SET_INTERPRETATION_CHANNEL_LOADING(state, loading) {
    state.interpretationChannelLoading = loading;
  },
  SET_INTERPRETATION_CHANNEL_ACTIVE(state, loading) {
    state.interpretationChannelActive = loading;
  },
  SET_INTERPRETER_CONSOLE_CHANNEL(state, channel) {
    if (channel.channel_engaged_by_me) {
      state.interpreterConsoleChannels.forEach((c, key) => {
        Vue.set(state.interpreterConsoleChannels, key, { ...state.interpreterConsoleChannels[key], ...{ channel_engaged_by_me: false } });
      })
    }
    const idx = state.interpreterConsoleChannels.findIndex(x => x.language_id === channel.language_id)
    if (idx > -1) {
      Vue.set(state.interpreterConsoleChannels, idx, { ...state.interpreterConsoleChannels[idx], ...channel });
    } else {
      state.interpreterConsoleChannels.push(channel);
    }
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_MESSAGE(state, message) {
    state.message = message;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
};

export const actions = {

  addMeetingParticipant({ commit }, connection) {
    commit("SET_LOADING", true);
    var data = helpers.stringToJson(connection.data);
    const participant = {
      data: data,
      initials: helpers.getInitials(data.name)
    }
    commit("ADD_MEETING_PARTICIPANT", participant);
    commit("SET_LOADING", false);
  },
  removeMeetingParticipant({ state, commit }, connection) {
    commit("SET_LOADING", true);
    var data = helpers.stringToJson(connection.data);
    const participant = state.meetingParticipants.find(x => x.data.user_id && x.data.user_id === data.user_id);
    commit("REMOVE_MEETING_PARTICIPANT", participant);
    commit("SET_LOADING", false);
  },

  getAvailableLanguagesInMeeting({ commit }, meeting_id) {
    commit("SET_LOADING", true);
    JoinMeetingService.getAvailableLanguagesInMeeting(meeting_id)
      .then((response) => {
        commit("SET_AVAILABLE_LANGUAGES", response.data.data);
        commit("SET_SELECTED_LANGUAGE_ID", response.data.data[0].id);
        commit("SET_LOADING", false);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", helpers.getError(error));
      });
  },
  HANDLE_MEETING_UPDATES({ commit }, updatedMeeting) {
    updatedMeeting = JSON.parse(updatedMeeting)
    commit("meetingSettings/SET_MODERATION_PARTICIPANTS_CAN_UNMUTE", updatedMeeting.moderation_allow_participants_to_unmute, { root: true });
    commit("meetingSettings/SET_MODERATION_PARTICIPANTS_CAN_SHOW_WEBCAM", updatedMeeting.moderation_allow_participants_to_show, { root: true });
    commit("meetingSettings/SET_MODERATION_PARTICIPANTS_CAN_SCREENSHARE", updatedMeeting.moderation_allow_participants_to_screenshare, { root: true });
    commit("meetingSettings/SET_MODERATION_PARTICIPANTS_CAN_WRITE_IN_CHAT", updatedMeeting.moderation_allow_participants_to_write_in_chat, { root: true });
  }
};

export const getters = {
  meetingParticipants: (state) => {
    return state.meetingParticipants;
  },
  interpreterConsoleChannels: (state) => {
    return state.interpreterConsoleChannels;
  },
  getChannelByLanguageId: (state) => (language_id) => {
    return state.interpreterConsoleChannels.find((x) => x.language_id === language_id)
  },
  atLeastOneInterpreterInMySelectedLanguageIsSpeakingAndListenToInterpretationIsEnabled: state => {
    return (state.interpreterConsoleChannels.find((x) => x.language_id === state.selectedLangId)?.channel_engaged_by_other || state.interpretationChannelActive) && state.audioSource == audioSource.INTERPRETING
  },
  floorAudio: state => {
    return !getters.atLeastOneInterpreterInMySelectedLanguageIsSpeaking && state.audioSource == audioSource.FLOOR
  },
  myInterpretingOutputChannelIsActive: state => {
    let at_least_one_channel_engaged_by_me = false;
    state.interpreterConsoleChannels.forEach(c => { c.channel_engaged_by_me ? at_least_one_channel_engaged_by_me = true : null; })
    return at_least_one_channel_engaged_by_me;
  },
  loading: (state) => {
    return state.loading;
  },
  message: (state) => {
    return state.message;
  },
  error: (state) => {
    return state.error;
  },
};

